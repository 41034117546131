import { Card } from 'antd';
import { useEffect, useState } from 'react';
import Txform from './Txform';
import Txrecord from './Txrecord';
import Moneyrecord from './Moneyrecord';
import UserInfo from './UserInfo';
import './index.scss'
import { useSelector } from 'react-redux';


  const tabList = [
    {
      key: 'tab1',
      tab: '提现',
    },
    {
      key: 'tab2',
      tab: '提现记录',
    },
    {
      key: 'tab4',
      tab: '余额变动记录',
    }
  ];

  const contentList = {
    tab1: <Txform />,
    tab2: <Txrecord/>,
    tab4: <Moneyrecord/>,
  };

  const windowWidth = window.innerWidth;

  if(windowWidth<790){
    tabList.unshift({key:'tab3',tab:"个人资料"})
    contentList.tab3 = <UserInfo/>
  }

const Withdrawal = ()=>{
    const [activeTabKey, setActiveTabKey] = useState('tab1');
    const userInfo = useSelector(state=>state.user.userInfo)
    useEffect(()=>{
      if(windowWidth<790){
        setActiveTabKey('tab3')
      }
    },[])
    
    const onTab1Change = (key) => {
        setActiveTabKey(key);
      };
    return(
        <Card
            style={{
            width: '100%',
            }}
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={onTab1Change}
            tabBarExtraContent={windowWidth<790 && <span style={{color: '#f0f0f0'}}>钱包余额：¥{ userInfo.availableBalance }</span> }
        >
            {contentList[activeTabKey]}
      </Card>
    )
}

export default Withdrawal;