import Table from "@/components/Table"
import { Input, Space, Modal,Form,Select,DatePicker,Button,Switch, message  } from 'antd';
import { createRef, useEffect, useState } from "react";
import Dialog from "@/components/Dialog";
import './index.scss'
import api from '@/api'
import { useSelector,useDispatch } from "react-redux";
import { fetchUserInfo } from '@/store/modules/user'
import { tool } from '@/utils'

const { RangePicker } = DatePicker;



const Wpage = () =>{
    const tableRef = createRef()
    const userInfo = useSelector(state=>state.user.userInfo)
    const [form] = Form.useForm();
    const dispatch = useDispatch()

    const [showDetail,setShowDetail] = useState(false)
    const [showOrderNo,setShowOrderNo] = useState('')
    const [list,setList] = useState([])
    const [filterForm,setFilterForm] = useState({})
    const [title,setTitle] = useState('')
    const [flag,setFlag] = useState(true)
    const [showTip,setShoTip] = useState(false)
    const [tipTitle,setTipTitle] = useState('')
    const [tipContent,setTipContent] = useState('')
    const [tipFooter,setTipFooter] = useState('')
    const [loading,setLoading] = useState(false)

    const onFinish = (values) => {

        if(values.status == 3){
            values.status=[3,8,10]
        }else if(values.status == 4){
            values.status = [4,11]
        }
        else if(values.status===0 || values.status){
            values.status=[values.status]
        }
        
        if(values.status == ''){
            values.status = [0,1,2,3,4,5,6,7,8,10]
        }

        if(values.date){
          values.dateRange = []
          for(let i = 0;i<2;i++){
            values.dateRange.push(tool.dateFormat(values.date[i],'yyyy-MM-dd'))
          }
        }

        setFilterForm(values)
    };

    const operFunc = (flag)=>{
        setShowDetail(flag)
    }

    const operTipFunc = (flag)=>{
      setShoTip(flag)
  }

    const openDetail =(orderNo)=>{
        setShowOrderNo(orderNo)
        getDetail(1,orderNo) 
        setShowDetail(true)
    }

    const getDetail =async (pageNum,orderNo) => {
        let params = {
            pageSize:5,
            pageNum:pageNum,
            orderNo:orderNo
        }
        let res = await api.order.detail.post(params);
        setList(res.data)
    }

    const columns = [
        {
          title: '订单号',
          dataIndex: 'orderNo',
          key: 'orderNo',
        },
        {
          title: '预计回收价格',
          dataIndex: 'preExchangePrice',
          key: 'preExchangePrice',
        },
        {
          title: '回收价格',
          dataIndex: 'realExchangePrice',
          key: 'realExchangePrice',
        },
        {
          title: '订单状态',
          key: 'orderStatus',
          dataIndex: 'orderStatus',
          render:(_,{ orderStatus })=>(
            <>
                { orderStatus==0 && <span style={{color:'#E98E1B'}}> · 待审核 </span> }
                { orderStatus==1 && <span style={{color:'#E98E1B'}}> · 待处理 </span> }
                { orderStatus==2 && <span style={{color:'#DE1F0F'}}> · 已驳回 </span> }
                { orderStatus==3 && <span style={{color:'#20BB19'}}> · 已完成 </span> }
                { orderStatus==4 && <span style={{color:'#DE1F0F'}}> · 已拒绝 </span> }
                { orderStatus==11 && <span style={{color:'#DE1F0F'}}> · 已拒绝 </span> }
                { orderStatus==5 && <span style={{color:'#DE1F0F'}}> · 报价错误 </span> }
                { orderStatus==6 && <span style={{color:'#DE1F0F'}}> · 卖家取消 </span> }
                { orderStatus==7 && <span style={{color:'#E98E1B'}}> · 交易暂挂 </span> }
                { orderStatus==10 && <span style={{color:'#E98E1B'}}> · 已完成 </span> }
                { orderStatus==8 && <span style={{color:'#20BB19'}}> · 已完成 </span> }
            </>
          )
        },
        {
          title: '订单创建时间',
          key: 'createAt',
          dataIndex: 'createAt',
        },
        {
          title: '订单完成时间',
          key: 'successAt',
          dataIndex: 'successAt',
        },
        {
          title: '操作',
          key: 'action',
          render: (_, record) => (
            record.orderStatus==1 &&
            <div className="action-btns">
                <Button className="btn" type="default" size="small" onClick={()=>showTips(record,'receipt')}>接受报价</Button>
                <Button className="btn" ghost size="small" onClick={()=>showTips(record,'reject')}>拒绝报价</Button>
            </div>
          ),
        },
        {
            title: '饰品详情',
            key: 'action',
            render: (_, record) => (
              <Space size="small" direction="vertical">
                <Button type="primary" onClick={()=>openDetail(record.orderNo)}>查看详情</Button>
              </Space>
            ),
          }
      ];
     
      

      const changeAuto = (val)=>{
        setLoading(true)
        api.user.changeAuto.get(val).then(res=>{
            if(res.code == 0){
                message.success("操作成功")
                dispatch(fetchUserInfo())
            }else{
                message.error(res.msg)
            }
            setLoading(false)
        })
      }

      const showTips = (record,type)=>{
        if(type == 'receipt'){
          setTipTitle( <p className="confirm-title">是否确认接受该订单结算金额</p> )
          setTipContent(<p className="confirm-content">￥{ record.realExchangePrice }</p>)
          setTipFooter(
            <div className="confirm-btns">
                <Button className="btn" onClick={()=>setShoTip(false)}>取 消</Button>
                <Button className="btn" type='primary' onClick={()=>operate(record,type)}>确 认</Button>
            </div>
          )
        }else{
          setTipTitle(<p className="confirm-title"><i className="warning"></i> 温馨提示</p>)
          setTipContent(<p className="confirm-tips">是否确认取消报价，多次取消会禁用账号</p>)
          
          setTipFooter(
            <div className="confirm-btns">
                <Button className="btn" onClick={()=>setShoTip(false)}>取 消</Button>
                <Button className="btn" type='primary' onClick={()=>operate(record,type)}>确 认</Button>
            </div>
          )
        }
        setShoTip(true)
      }

      const operate = (record,type)=>{
        if(!flag){
          message.error('操作进行中，请勿重复点击~~')
          return;
        }
        setFlag(false)
        let params = {
          orderNo:record.orderNo,
          type:type
        }
        api.order.operate.post(params).then(res=>{
            if(res.code == 0){
                message.success('操作成功')
                if(type=='receipt'){
                  record.orderStatus = 3
                }else{
                  record.orderStatus = 4
                }
            }else{
                message.error(res.msg)
            }
            setFlag(true)
            setShoTip(false)
        })
      }
      useEffect((val)=>{
        getSum();
      },[filterForm])

      async function getSum(){
        let res = await api.order.statistics.post({...filterForm,pageNum:1,pageSize:1})
        if(res.code == 0){
            var title1 =  <Space size="middle"> <span>总订单笔数 ：{ res.data.totalOrderCount }</span>  <span>总订单金额 ：¥ { res.data.totalOrderAmount }</span>  </Space> 
            setTitle(title1)
        }
      }

    return(
        <>
          <Table 
            title={title} columns={columns} data={api.order.list} filterForm={ filterForm } rowKey="id" ref={tableRef}
            expandable={{
                expandedRowRender: (record) => (
                  <p
                    style={{
                      margin: 0,
                    }}
                  >
                    {record.orderRemark}
                  </p>
                ),
                rowExpandable:(record) => record.orderRemark !== null,
              }}
            >
              <div className="form-wrap">
                  <div className="left">
                      <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
                          <Form.Item
                              name="status"
                              label="订单状态"
                          >
                              <Select
                                  style={{ width: 107 }}
                                  defaultValue=""
                              >
                                  <Select.Option value="">全部</Select.Option>
                                  <Select.Option value={1}>待处理</Select.Option>
                                  <Select.Option value={2}>已驳回</Select.Option>
                                  <Select.Option value={3}>已完成</Select.Option>
                                  <Select.Option value={4}>已拒绝</Select.Option>
                                  <Select.Option value={5}>报价错误</Select.Option>
                                  <Select.Option value={6}>已取消</Select.Option>
                                  <Select.Option value={7}>交易暂挂中</Select.Option>
                                  <Select.Option value={0}>待审核</Select.Option>
                              </Select>
                          </Form.Item>
                          <Form.Item
                              name="orderNo"
                              label="订单号"
                              
                          >
                              <Input
                                  placeholder="请输入订单号"
                              />
                          </Form.Item>
                          <Form.Item 
                              name="date"
                              label="时间"
                          >
                              <RangePicker format="YYYY-MM-DD" />
                          </Form.Item>
                          <Form.Item>
                              <Space>
                                  <Button type="default" htmlType="reset">重置</Button>
                                  <Button type="primary" htmlType='submit'>搜索</Button>
                              </Space>
                          </Form.Item>
                      </Form>
                  </div>
                  <div>
                      <Space>
                          <label>自动收货 </label>
                          <Switch checked={ userInfo.isAutoReceive } onChange={ changeAuto } loading={loading} />
                      </Space>
                  </div>
              </div>
          </Table>
          <Dialog title="饰品详情" 
                footer = {<></>}
                vis={ showDetail }
                operDialogFunc={operFunc}>
                <p>订单号：{showOrderNo}</p>
                <div className="weaponlist">
                    {list.map((item)=>(
                        <div className="item" key={item.itemImg}>
                            <div className="img-wrap">
                                <img src={item.itemImg} alt={ item.itemName }/>
                            </div>
                            <div className="content">{ item.itemName }</div>
                        </div>
                    ))}
                </div>
          </Dialog> 
           
          <Dialog
            title={ tipTitle }
            footer = {<></>}
            vis={ showTip }
            width={280}
            closable={false}
            operDialogFunc={operTipFunc}
            >
              { tipContent }
              {tipFooter}
          </Dialog>
        </>
        
    )
}
export default Wpage