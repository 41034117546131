import Table from "@/components/Table"
import { Input, Space, Tag,Form,Select,DatePicker,Button,Switch,Empty,Pagination  } from 'antd';
import { useEffect, useState } from "react";
import api from '@/api'
import { tool } from "@/utils";

const columns = [
    {
      title: '关联订单号',
      dataIndex: 'orderNo',
      key: 'orderNo',
    },
    {
      title: '操作时间',
      key: 'actionAt',
      dataIndex: 'actionAt',
      render: (_, { actionAt }) => (
        actionAt?(tool.dateFormat(actionAt)):'--'
      )
    },
    {
      title: '变动金额',
      key: 'amount',
      dataIndex: 'amount',
    },
    {
      title: '变动后余额',
      key: 'balance',
      dataIndex: 'balance',
    },{
      title: '变动原因',
      key: 'content',
      dataIndex: 'content'
    }
  ];

const Moneyrecord = () =>{
    const [total,setTotal] = useState(0)
    const [list,setList] = useState([]);

    useEffect(()=>{
      getData(1)
    },[])

    async function getData(page){
      let params = {
        pageNum:page,
        pageSize:5
      }
      let res = await api.withdraw.moneylogs.post(params);
      if(res.code == 0){
        setTotal(res.totalCount)
        setList(res.data)
      }
    }


    return(
      <>
        { window.innerWidth>790 &&  <Table title={false} columns={columns} data={api.withdraw.moneylogs} ></Table>}
        
        { window.innerWidth<790 &&
          <div className="record-list">
            {list.map((item)=>(
              <div className="item" key={item.orderNo}>
                <div className="top">
                  <div>
                    <i className="time"></i>
                    <span> { tool.dateFormat(item.actionAt)} </span>
                  </div>
                </div>
                <div>
                  <p>变动金额： <span>￥ { item.amount }</span></p>
                  <p>变动后余额： <span>￥{ item.balance }</span></p>
                </div>
                <div>
                  <p>{ item.content }</p>
                </div>
              </div>
            ))}
            {list.length<=0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {total>5 && <Pagination defaultCurrent={1} pageSize={5} total={total} onChange={ getData }/> } 
          </div>
        }
      </>
    )
}
export default Moneyrecord