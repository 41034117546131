import "./index.css"
import { useNavigate } from 'react-router-dom'
import Login from "@/components/Login";
import { useEffect, useState } from "react";
import { getToken } from "@/utils/token";
import { Avatar,Popover,message } from "antd";
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInfo,clearUserInfo } from '@/store/modules/user'

const TopBar = ()=>{
    const navigate = useNavigate();
    const pathname = window.location.pathname
    const [visiable,setVisiable]=useState(false)
    const dispatch = useDispatch()
    const openDialog = ()=>{
        setVisiable(true)
    }
    const operDialogFunc = (flag)=>{
        setVisiable(flag)
    }
    const token = getToken();

    const text = '';
    const logout = ()=>{
        dispatch( clearUserInfo() )
        navigate('/')
        // 2.提示一下用户
        message.success('退出成功')
    }
    useEffect(()=>{
        if(token){
            dispatch(fetchUserInfo())
        }
    },[dispatch])
    const userInfo =  useSelector(state=>state.user.userInfo)

    const content = (
        <div className="popover">
            <div onClick={ ()=>{navigate('/center')} }><i className="person" ></i> 个人中心</div>
            <div onClick={logout}><i className="exit"></i> 退出账号</div>
        </div>
    );
    
  


    return(
        <div className="topBar">
            <div className="content">
                <div className="left">
                    <div className="logo-wrap">
                        <img src="/logo512.png" alt="logo"></img>
                    </div>
                    <div className="menus">
                        <div className={pathname=='/'?'item active':'item'} onClick={() => navigate('/')}>
                            <i className="index"></i>
                            <span>首页</span>
                        </div>
                        <div className={pathname=='/receivelist'?'item active':'item'} onClick={() => navigate('/receivelist')}>
                            <i className="receivelist"></i>
                            <span>回收列表</span>
                        </div>
                        {/* <div className={pathname=='/shops'?'item active':'item'} onClick={() => navigate('/shops')}>
                            <i className="shop"></i>
                            <span>回收店铺</span>
                        </div> */}
                        <div className={pathname=='/order'?'item active':'item'} onClick={() => navigate('/order')}>
                            <i className="order"></i>
                            <span>我的订单</span>
                        </div>
                        <div className={pathname=='/invite'?'item active':'item'} onClick={() => navigate('/invite')}>
                            <i className="invite"></i>
                            <span>邀请奖金</span>
                        </div>
                        <div className={pathname=='/notic'?'item active':'item'} onClick={() => navigate('/notic')}>
                            <i className="notic"></i>
                            <span>系统公告</span>
                        </div>
                        
                    </div>
                </div>
                <div className="right">
                    {!token && <div className="button" onClick={openDialog}>登录</div>}
                    {token && 
                        <div className="userinfo"> 
                            <div className="left"> <p>{userInfo.nickname}</p> <p>￥{ userInfo.availableBalance }</p></div>  
                            <div>
                                <Popover placement="bottomRight" title={text} content={content}>
                                    <Avatar size={32} > {userInfo.nickname && userInfo.nickname.substring(0,1) } </Avatar>
                                </Popover>
                            </div> 
                        </div>
                    }
                </div>
            </div>
            <Login vis={visiable} operDialogFunc={ operDialogFunc }/>
        </div>
    )
}

export default TopBar